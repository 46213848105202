<template>
  <Top></Top>
  <h3>碳循環週期設定</h3>
  <div class="uk-grid-divider uk-margin-large-top" uk-grid>
    <Personal :showSave="false"></Personal>
    <div
      class="
        uk-width-expand@m
        uk-margin-left
        uk-margin-right
        uk-margin-remove-left@m
        uk-margin-remove-right@m
      "
    >
      <Goal :showCarbCycling="true"></Goal>
      <div class="uk-margin-medium-top">
        <button
          class="uk-button uk-button-secondary uk-width-1-1"
          @click="save"
        >
          儲存
        </button>
      </div>
    </div>
    <div
      class="
        uk-width-expand uk-margin-left uk-margin-right uk-margin-remove-left@m
      "
    >
      <div class="uk-child-width-1-1 uk-grid-medium uk-grid-match" uk-grid>
        <div>
          <div class="uk-card uk-card-body uk-tile uk-tile-muted">
            <small>今日是</small>
            <p class="uk-card-title">{{ carbsDayName }}</p>
            <p class="uk-card-title">
              目標熱量 {{ todayCalorie.toFixed(0) }} 卡
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <ul uk-accordion="multiple: true" class="uk-text-left">
          <li :class="{ 'uk-open': carbsDay === 0 }">
            <a class="uk-accordion-title" href="#">高碳日</a>
            <div class="uk-accordion-content">
              <nutrition
                :calorieGoal="0"
                :portein="calcCarbsNutrition[0]?.portein"
                :carbs="calcCarbsNutrition[0]?.carbs"
                :fat="calcCarbsNutrition[0]?.fat"
              ></nutrition>
            </div>
          </li>
          <li :class="{ 'uk-open': carbsDay === 1 }">
            <a class="uk-accordion-title" href="#">中碳日</a>
            <div class="uk-accordion-content">
              <nutrition
                :calorieGoal="0"
                :portein="calcCarbsNutrition[1]?.portein"
                :carbs="calcCarbsNutrition[1]?.carbs"
                :fat="calcCarbsNutrition[1]?.fat"
              ></nutrition>
            </div>
          </li>
          <li :class="{ 'uk-open': carbsDay === 2 }">
            <a class="uk-accordion-title" href="#">低碳日</a>
            <div class="uk-accordion-content">
              <nutrition
                :calorieGoal="0"
                :portein="calcCarbsNutrition[2]?.portein"
                :carbs="calcCarbsNutrition[2]?.carbs"
                :fat="calcCarbsNutrition[2]?.fat"
              ></nutrition>
            </div>
          </li>
        </ul>
      </div>
      <div class="uk-text-left">
          <div class="uk-margin-small-bottom">
            <span class="uk-margin-small-right" uk-icon="icon: question"></span>
            <b class="uk-text-small"
              ><router-link to="/carbs-cycling-rule" class="uk-link-text">查看碳循環計算規則</router-link></b
            >
          </div>
        </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Nutrition from "../components/Nutrition.vue";
import * as T from "../models/Nutrition";
import Goal from "../components/Goal.vue";
import Personal from "../components/Personal.vue";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
  components: {
    Nutrition,
    Goal,
    Personal,
  },
  computed: {
    todayCalorie(): number {
      const n: T.Nutrition | null = this.calcCarbsNutrition[this.carbsDay];
      if (n) {
        return n.portein * 4 + n.carbs * 4 + n.fat * 9;
      }
      return 0;
    },
    carbsDay(): number {
      var day = new Date().getDay();
      day = day >= 7 ? 0 : day;
      if (this.highCarbsDay.includes(day)) {
        return 0;
      } else if (this.mediumCarbsDay.includes(day)) {
        return 1;
      } else if (this.lowCarbsDay.includes(day)) {
        return 2;
      }
      return -1;
    },
    carbsDayName(): string {
      var day = new Date().getDay();
      day = day >= 7 ? 0 : day;
      if (this.highCarbsDay.includes(day)) {
        return "高碳日";
      } else if (this.mediumCarbsDay.includes(day)) {
        return "中碳日";
      } else if (this.lowCarbsDay.includes(day)) {
        return "低碳日";
      }
      return "";
    },
    calcCarbsNutrition(): Array<T.Nutrition | null> {
      const highCarbsDays = this.highCarbsDay.length;
      const mediumCarbsDays = this.mediumCarbsDay.length;
      const lowCarbsDays = this.lowCarbsDay.length;
      const porteinCalorie = 4;
      const carbsCalorie = 4;
      const fatCalorie = 9;
      const fatPercent = 0.85;
      const fatOfDay = this.weight * fatPercent;
      const porteinOfDay = this.porteinGoal;
      if (highCarbsDays + mediumCarbsDays + lowCarbsDays < 7) {
        const obj: T.Nutrition | null = null;
        return [obj, obj, obj];
      }

      const carbsOfDay =
        (this.calorieGoal -
          (porteinOfDay * porteinCalorie + fatOfDay * fatCalorie)) /
        carbsCalorie;

      return [
        {
          portein: porteinOfDay,
          carbs: (carbsOfDay * 7 * 0.5) / highCarbsDays,
          fat: (fatOfDay * 7 * 0.15) / highCarbsDays,
        },
        {
          portein: porteinOfDay,
          carbs: (carbsOfDay * 7 * 0.35) / mediumCarbsDays,
          fat: (fatOfDay * 7 * 0.25) / mediumCarbsDays,
        },
        {
          portein: porteinOfDay,
          carbs: (carbsOfDay * 7 * 0.15) / lowCarbsDays,
          fat: (fatOfDay * 7 * 0.6) / lowCarbsDays,
        },
      ];
    },
    ...mapState([
      "weight",
      "highCarbsDay",
      "mediumCarbsDay",
      "lowCarbsDay",
      "isLogin",
    ]),
    ...mapGetters(["calorieGoal", "porteinGoal"]),
  },
  methods: {
    save() {
      const w = window as any;
      this.$store
        .dispatch("updateUserData")
        .then(() => {
          w.UIkit.notification("儲存完成", {
            status: "success",
            timeout: 1000,
          });
        })
        .catch(() => {
          w.UIkit.notification("儲存失敗", { status: "danger", timeout: 1000 });
        });
    },
  },
});
</script>
