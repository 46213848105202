
import { defineComponent } from "vue";
import Nutrition from "../components/Nutrition.vue";
import * as T from "../models/Nutrition";
import Goal from "../components/Goal.vue";
import Personal from "../components/Personal.vue";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
  components: {
    Nutrition,
    Goal,
    Personal,
  },
  computed: {
    todayCalorie(): number {
      const n: T.Nutrition | null = this.calcCarbsNutrition[this.carbsDay];
      if (n) {
        return n.portein * 4 + n.carbs * 4 + n.fat * 9;
      }
      return 0;
    },
    carbsDay(): number {
      var day = new Date().getDay();
      day = day >= 7 ? 0 : day;
      if (this.highCarbsDay.includes(day)) {
        return 0;
      } else if (this.mediumCarbsDay.includes(day)) {
        return 1;
      } else if (this.lowCarbsDay.includes(day)) {
        return 2;
      }
      return -1;
    },
    carbsDayName(): string {
      var day = new Date().getDay();
      day = day >= 7 ? 0 : day;
      if (this.highCarbsDay.includes(day)) {
        return "高碳日";
      } else if (this.mediumCarbsDay.includes(day)) {
        return "中碳日";
      } else if (this.lowCarbsDay.includes(day)) {
        return "低碳日";
      }
      return "";
    },
    calcCarbsNutrition(): Array<T.Nutrition | null> {
      const highCarbsDays = this.highCarbsDay.length;
      const mediumCarbsDays = this.mediumCarbsDay.length;
      const lowCarbsDays = this.lowCarbsDay.length;
      const porteinCalorie = 4;
      const carbsCalorie = 4;
      const fatCalorie = 9;
      const fatPercent = 0.85;
      const fatOfDay = this.weight * fatPercent;
      const porteinOfDay = this.porteinGoal;
      if (highCarbsDays + mediumCarbsDays + lowCarbsDays < 7) {
        const obj: T.Nutrition | null = null;
        return [obj, obj, obj];
      }

      const carbsOfDay =
        (this.calorieGoal -
          (porteinOfDay * porteinCalorie + fatOfDay * fatCalorie)) /
        carbsCalorie;

      return [
        {
          portein: porteinOfDay,
          carbs: (carbsOfDay * 7 * 0.5) / highCarbsDays,
          fat: (fatOfDay * 7 * 0.15) / highCarbsDays,
        },
        {
          portein: porteinOfDay,
          carbs: (carbsOfDay * 7 * 0.35) / mediumCarbsDays,
          fat: (fatOfDay * 7 * 0.25) / mediumCarbsDays,
        },
        {
          portein: porteinOfDay,
          carbs: (carbsOfDay * 7 * 0.15) / lowCarbsDays,
          fat: (fatOfDay * 7 * 0.6) / lowCarbsDays,
        },
      ];
    },
    ...mapState([
      "weight",
      "highCarbsDay",
      "mediumCarbsDay",
      "lowCarbsDay",
      "isLogin",
    ]),
    ...mapGetters(["calorieGoal", "porteinGoal"]),
  },
  methods: {
    save() {
      const w = window as any;
      this.$store
        .dispatch("updateUserData")
        .then(() => {
          w.UIkit.notification("儲存完成", {
            status: "success",
            timeout: 1000,
          });
        })
        .catch(() => {
          w.UIkit.notification("儲存失敗", { status: "danger", timeout: 1000 });
        });
    },
  },
});
